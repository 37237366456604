import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { PlusOrMinus, VatMessage } from "@vp/vp-tokenized-fragment";
import { P } from "@vp/swan";
import { isVatInclusive } from "@shared/utils/Pricing";
import { useAppSelector } from "@shared/redux";
import classNames from "classnames";

const messages = defineMessages({
    forQuantity: {
        id: "studio.differentialPricingComponent.forQuantity",
        defaultMessage: "for [[quantity]]",
        description: {
            note: "This would be used like [cost] for [quantity], so $10 for 300 cards"
        }
    },
    included: {
        id: "studio.differentialPricingComponent.included",
        defaultMessage: "Included"
    }
});

export interface DifferentialPricing {
    listPrice?: string | number;
    discountPrice: number;
}

export interface DifferentialPricingProps {
    pricing?: DifferentialPricing;
    hideQuantity?: boolean;
    hideVatMessage?: boolean;
    negatePricingValues?: boolean;
    placeHolderText?: string;
    className?: string;
    priceIncludedClassname?: string;
}

export function DifferentialPricingDisplay(props: DifferentialPricingProps) {
    const {
        pricing,
        hideQuantity,
        hideVatMessage,
        negatePricingValues,
        placeHolderText = "...",
        className,
        priceIncludedClassname
    } = props;
    const { t } = useTranslationSSR();
    const currency = useAppSelector(state => state.currency);
    const locale = useAppSelector(state => state.locale);
    const quantity = useAppSelector(state => state.quantity);

    if (!pricing || typeof pricing.listPrice === "undefined") {
        return null;
    }
    if (pricing.listPrice === "Included" || pricing.listPrice === 0) {
        return (
            <span data-translationid={messages.included.id} className={classNames(className, priceIncludedClassname)}>
                {t(messages.included.id)}
            </span>
        );
    }
    const vatInc = isVatInclusive();
    let { listPrice, discountPrice } = pricing;
    if (negatePricingValues) {
        listPrice = -1 * (listPrice as number);
        discountPrice *= -1;
    }
    return (
        <P className={classNames(className)} data-translationid={messages.forQuantity.id}>
            <PlusOrMinus
                placeHolderText={placeHolderText}
                pricingInfo={{
                    currency,
                    minimumFractionDigits: 2,
                    culture: locale,
                    priceDifference: listPrice as number,
                    priceDifferenceDiscounted: discountPrice,
                    // @ts-ignore
                    positiveDifference: true
                }}
            />
            {!hideQuantity && quantity && ` ${t(messages.forQuantity.id, { quantity })}`}{" "}
            {!hideVatMessage && (
                <VatMessage
                    placeHolderText="..."
                    pricingInfo={{
                        culture: locale,
                        vatInc
                    }}
                />
            )}
        </P>
    );
}

DifferentialPricingDisplay.displayName = "DifferentialPricingDisplay";
